import React from 'react';
import { graphql } from 'gatsby';
import { SEO } from 'src/components/common';
import { ListPage } from 'src/components/post';
import type { PageProps } from 'gatsby';
import type { GetYearPostsQuery } from 'types/graphql-type';
import type { PageYearContextType } from 'types/custom-type';

type Props = PageProps<GetYearPostsQuery, PageYearContextType>;

export const query = graphql`
  query GetYearPosts($year: String!) {
    posts: allPrismicPost(
      limit: 100
      sort: { fields: [data___date], order: DESC }
      filter: {
        data: { years: { elemMatch: { year: { uid: { eq: $year } } } } }
      }
    ) {
      ...PostList
    }
  }
`;

const Category: React.FC<Props> = ({
  pageContext: { year, yearLabel },
  data: { posts },
  location: { pathname },
}: Props) => (
  <>
    <SEO title={`開催プログラム Category: ${yearLabel}`} pathname={pathname} />
    <ListPage posts={posts} year={year} />
  </>
);

export default Category;
